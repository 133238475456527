import { useSnackbar } from "notistack";
import { ChangeEvent, useContext, useState, KeyboardEvent } from "react"
import { AuthContext } from "../../../context/AuthContext";
import { ResultOptions } from "../../../Types/ResultOptions";
import { useUploadToolbarHelpers } from "./UploadToolbarHelpers";
import { useUploadStore } from "../../../Types/Plm/UploadStore";
import { useUploadGridLoaders } from "../grid/hooks/UploadGridLoaders";

export const useUploadToolbarEvents = () => {
    const authContext = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const { setSearchDocumentText, setProcessingUpload, testGridProps, ecnGridProps } = useUploadStore();
    const { getUploadDocsByCode } = useUploadGridLoaders();
    const { uploadSPOQCReports } = useUploadToolbarHelpers();
    const [searchDocumentValue, setSearchDocumentValue] = useState<string>('');

    const checkWhichGrid = () => {
        if (testGridProps && (testGridProps.docCode?.length ?? 0) > 0) {
            getUploadDocsByCode(testGridProps.docCode);
        }

        if (ecnGridProps && (ecnGridProps.docCode?.length ?? 0) > 0) {
            getUploadDocsByCode(ecnGridProps.docCode);
        }
    }
    const onChangeHandlerSearchToolbar = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchDocumentValue(event.target.value);
    }
    const onKeyUpHandlerSearchToolbar = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter" || event.nativeEvent.code === "Enter" || event.code === "NumpadEnter" || event.nativeEvent.code === "NumpadEnter") {
            setSearchDocumentText(searchDocumentValue);
        }
    }
    const onChangeHandlerUploadToolbar = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist();
        if (event.target.files && event.target.files.length > 0) {
            Array.from(event.target.files).forEach((file, idx) => {
                setProcessingUpload(true);
                uploadSPOQCReports(
                    authContext?.auth.userToken ?? '',
                    file,
                    (data: ResultOptions) => {
                        if (!data.success) {
                            enqueueSnackbar(data.message, { variant: "error", autoHideDuration: 10000 });
                        } else {
                            enqueueSnackbar(`File (${file.name}) has been uploaded successfully.`, { variant: "default", autoHideDuration: 10000 });                            

                            checkWhichGrid();
                        }
                        event.target.value = "";
                        setProcessingUpload(false);
                    },
                    (err: any) => {
                        enqueueSnackbar("File upload process failed.", { variant: "error", autoHideDuration: 10000 });
                        console.log('err', err);
                        event.target.value = "";
                        setProcessingUpload(false);
                    });
            });
        }
    }
    return {
        onChangeHandlerUploadToolbar,
        onChangeHandlerSearchToolbar,
        onKeyUpHandlerSearchToolbar,
    }
}