import { DocumentFile } from "../../../Types/Plm/DocumentFile";
import { getAllStyleDocFiles } from "../../../services/plm/styleDoc";

export const useUploadDataLoaders = () => {
    function loadEcnDoc(
        token: string,
        currentComponent: string,
        callback: (data: DocumentFile[]) => void,
        errCallback: (err: any) => void) {
        getAllStyleDocFiles(token, currentComponent)
            .then((data: DocumentFile[]) => {
                if (callback)
                    callback(data);
            }).catch((err: any) => {
                if (errCallback)
                    errCallback(err);
            })
    }
    return {
        loadEcnDoc
    }
}