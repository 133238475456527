import { styled } from "@mui/material"
import { UploadToolbar } from "./toolbars/UploadToolbar"
import { EcnGrid } from "./grid/EcnGrid"
import { sitemaps } from "../../config/sitemap"
import { useLocation } from 'react-router-dom';
import { constants } from "../../config/settings";
import { UploadProvider, useUploadStore } from "../../Types/Plm/UploadStore";
import { SCCPCGrid } from "./grid/SCCPCGrid";
import { LabelReqGrid } from "./grid/LabelReqGrid";
// import { SPOQCReportsProvider } from "../store/plm/SPOQCReportsStore"
// import { SPOQCReportsGrid } from "./grid/SPOQCReportsGrid"
// import { UploadToolbar } from "./toolbar/UploadToolbar"

export const UploadGridContainter = styled('div')({
    backgroundColor: '#f6f6f6',
    padding: '20px 0 0 20px',
})

export const UploadsDocContainer = () => {
    const location = useLocation();
    const _ecnDocuments = [constants.uploadDocTypes.ecrCode, constants.uploadDocTypes.ecnCode];
    const _testDocuments = [constants.uploadDocTypes.testCode, constants.uploadDocTypes.cpspcCode];    
    const getCurrentComponentLinks = (link: string) => {
        var module = sitemaps.find(x => x.items?.find(e => e.link === link));
        if (module != null && module !== undefined) {
            var component = module.items?.find(e => e.link === link);
            var name = component?.componentCode;
            return name;
        }
    }

    const renderGrid = () => {
        if (getCurrentComponentLinks(location.pathname) === constants.accessRightsComponents.ARDAUploadsESN) {
            return <EcnGrid docCode={_ecnDocuments.join(",")} />
        }

        if (getCurrentComponentLinks(location.pathname) === constants.accessRightsComponents.ARDAUploadsCPSPC) {
            return <SCCPCGrid docCode={_testDocuments.join(",")} />
        }

        if (getCurrentComponentLinks(location.pathname) === constants.accessRightsComponents.ARDAUploadsLABELREQ) {
            return <LabelReqGrid docCode={constants.uploadDocTypes.labelReq}/>
        }
    }
    return (
        <UploadProvider>
            <UploadToolbar />
            <UploadGridContainter>
                {renderGrid()}
            </UploadGridContainter>
        </UploadProvider>
    )
}

