import { createContext, Dispatch, SetStateAction, useContext, useState, useRef } from "react";
import { DocumentFile } from "./DocumentFile";
import { JsxElement } from "typescript";
import { SCCPCGridProps } from "../../components/uploadsdoc/grid/SCCPCGrid";
import { EcnGridProps } from "../../components/uploadsdoc/grid/EcnGrid";


export interface UploadContextData {
    loadingDocs: boolean;
    setLoadingDocs: Dispatch<SetStateAction<boolean>>;

    documentFiles: DocumentFile[];
    setDocumentFiles: Dispatch<SetStateAction<DocumentFile[]>>;

    searchDocumentText: string;
    setSearchDocumentText: Dispatch<SetStateAction<string>>;

    isReadonly: boolean;
    setIsReadonly: Dispatch<SetStateAction<boolean>>;

    processingUpload: boolean;
    setProcessingUpload: Dispatch<SetStateAction<boolean>>;

    currentComponent: string;
    setCurrentComponent: Dispatch<SetStateAction<string>>;
    
    testGridProps: SCCPCGridProps;
    setTestGridProps: Dispatch<SetStateAction<SCCPCGridProps>>;

    ecnGridProps: EcnGridProps;
    setECNGridProps: Dispatch<SetStateAction<EcnGridProps>>;
}

export const uploadContextDefaultValue: UploadContextData = {
    loadingDocs: false,
    setLoadingDocs: () => { },

    documentFiles: [],
    setDocumentFiles: () => { },

    searchDocumentText: '',
    setSearchDocumentText: () => { },

    isReadonly: false,
    setIsReadonly: () => { },

    processingUpload: false,
    setProcessingUpload: () => { },

    currentComponent: '',
    setCurrentComponent: () => { },

    testGridProps: {docCode:''},
    setTestGridProps: () => {},

    ecnGridProps: {docCode:''},
    setECNGridProps: ()=>{}
}

export const UploadStore = createContext<UploadContextData>(uploadContextDefaultValue);

export const useUploadStore = () => useContext(UploadStore);

export const UploadProvider: React.FC = ({ children }) => {
    const [loadingDocs, setLoadingDocs] = useState<boolean>(false);
    const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>([]);
    const [searchDocumentText, setSearchDocumentText] = useState<string>('');
    const [isReadonly, setIsReadonly] = useState<boolean>(false);
    const [processingUpload, setProcessingUpload] = useState<boolean>(false);
    const [currentComponent, setCurrentComponent] = useState<string>('');    
    const [testGridProps,setTestGridProps] = useState<SCCPCGridProps>({docCode:''});
    const [ecnGridProps,setECNGridProps] = useState<EcnGridProps>({docCode:''})

    return (
        <UploadStore.Provider
            value={{
                loadingDocs,
                setLoadingDocs,

                documentFiles,
                setDocumentFiles,

                searchDocumentText,
                setSearchDocumentText,

                isReadonly,
                setIsReadonly,

                processingUpload,
                setProcessingUpload,

                currentComponent,
                setCurrentComponent,

                testGridProps,
                setTestGridProps,

                ecnGridProps,
                setECNGridProps
            }}
        >
            {children}
        </UploadStore.Provider>
    )
}