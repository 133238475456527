import { constants } from "./settings";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import InventoryIcon from '@mui/icons-material/Inventory';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

export type VTModule = {
    id: number;
    name: string;
    componentCode: string;
    link: string;
    items?: VTModule[];
    admin: boolean;
    hide: boolean;
    isIndent: boolean;
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
    inProgress: boolean;
}

export type VTModules = {
    vtModule: VTModule[];
}

export const sitemaps = [
    {
        id: 1,
        name: 'BOM',
        componentCode: constants.accessRightsComponents.UploadBOM,
        Icon: InventoryIcon,
        admin: false,
        hide: false,
        items: [
            {
                id: 11,
                name: 'mBOM Conversion',
                componentCode: constants.accessRightsComponents.mBOMConversion,
                link: '/conversion',
                admin: false,
                hide: !constants.isDevelopment,
                inProgress: false,
            } as VTModule,
            {
                id: 12,
                name: 'Upload BOM',
                componentCode: constants.accessRightsComponents.UploadBOM,
                link: '/uploads',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
            {
                id: 13,
                name: 'BOM Overview',
                componentCode: constants.accessRightsComponents.BOMOverview,
                link: '/report',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
        ]
    },
    {
        id: 2,
        name: 'Production Planning',
        componentCode: constants.accessRightsComponents.ProductionPlanning,
        Icon: PendingActionsIcon,
        admin: false,
        hide: false,
        items: [
            {
                id: 21,
                name: 'Scheduler',
                componentCode: constants.accessRightsComponents.ProductionPlanning,
                link: '/planning',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
        ]
    },
    {
        id: 3,
        name: 'Uploads',
        // componentCode: constants.accessRightsComponents.ProductionPlanning,
        componentCode: constants.accessRightsComponents.ARDAUploads,
        Icon: PublishOutlinedIcon,
        admin: false,
        hide: false,
        items: [
            {
                id: 31,
                name: 'ECR Documents',
                // componentCode: constants.accessRightsComponents.ProductionPlanning,
                componentCode: constants.accessRightsComponents.ARDAUploadsESN,
                link: '/uploadsecn',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
            {
                id: 32,
                name: 'Label Requirements',
                // componentCode: constants.accessRightsComponents.ProductionPlanning,
                componentCode: constants.accessRightsComponents.ARDAUploadsLABELREQ,
                link: '/uploadslabelreq',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
            {
                id: 32,
                name: 'Testing Reports',
                // componentCode: constants.accessRightsComponents.ProductionPlanning,
                componentCode: constants.accessRightsComponents.ARDAUploadsCPSPC,
                link: '/uploadscpspc',
                admin: false,
                hide: false,
                inProgress: false,
            } as VTModule,
        ]
    },
] as VTModule[];