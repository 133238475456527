import { AppBar, Toolbar, Box, styled, Tooltip, IconButton, Typography, CircularProgress } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import { useEffect } from 'react';
import { Grow, Icons, Search, SearchIconWrapper, StyledInputBase } from '../../styles/SharedStyles';
import { constants } from '../../../config/settings';
import { useUploadToolbarEvents } from '../hooks/UploadToolbarEvents';
import { useUploadStore } from '../../../Types/Plm/UploadStore';

export const BoxContainer = styled(Box)({
    display: 'flex',
    maxHeight: '64px',
    [`& header.MuiPaper-root`]: {
        height: '64px',
        [`& .MuiToolbar-root`]: {
            backgroundColor: '#fff!important',
            color: '#000',
            minHeight: 64,
        }
    }
})


export const UploadToolbar = () => {
    const { onChangeHandlerUploadToolbar, onChangeHandlerSearchToolbar, onKeyUpHandlerSearchToolbar } = useUploadToolbarEvents();    
    const { isReadonly, processingUpload } = useUploadStore();        
    return (
        <BoxContainer
            sx={{ flexGrow: 1 }} >
            <AppBar
                position="static" >
                <Toolbar variant="dense">
                    <div>
                        <Typography variant="h5" sx={{ fontSize: '1em', fontWeight: '600' }}>
                            Uploads
                        </Typography>
                    </div>
                    <Grow />
                    <Search>
                        <SearchIconWrapper>
                            <SearchOutlinedIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={onChangeHandlerSearchToolbar}
                            onKeyUp={onKeyUpHandlerSearchToolbar}
                        />
                    </Search>
                    <Icons>
                        {(!processingUpload) &&
                            < label htmlFor="upload-request-toolbar-file">
                                <input
                                    style={{ display: "none" }}
                                    accept={`application/pdf`}
                                    id="upload-request-toolbar-file"
                                    name="upload-request-toolbar-file"
                                    multiple={true}
                                    type="file"
                                    onChange={(e) => onChangeHandlerUploadToolbar(e)}
                                />
                                <Tooltip title="Upload File">
                                    <IconButton aria-label="upload"
                                        style={{ color: '#1976d2' }}
                                        component="span">
                                        <PublishOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                        }

                        {(!isReadonly && processingUpload) &&
                            <IconButton
                                aria-label="upload in progress"
                                component="span">
                                <CircularProgress size={24} disableShrink />
                            </IconButton>
                        }
                    </Icons>
                </Toolbar>
            </AppBar>
        </BoxContainer >
    )
}