export const constants = {
    muiLicenseKey: '3f171372153df4c54d15cb70d6f4d7acT1JERVI6MzA1NDUsRVhQSVJZPTE2NjUyMTcwMzAwMDAsS0VZVkVSU0lPTj0x',
    smartComponentLicenseKey: '8414516F-15A2-4D84-A7AF-A9A72400DB02',
    documentExtensions: ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.txt', '.html', '.ini', '.ppt', '.pptx', '.msg', '.eml'],
    documentThumbnail: {
        'xls': './images/excel.png',
        'xlsx': './images/excel.png',
        'msg': './images/outlook.png',
        'eml': './images/outlook.png',
        'doc': './images/outlook.png',
        'docx': './images/outlook.png',
        'pdf': './images/pdf.png',
        'pptx': './images/powerpoint.png',
        'ppt': './images/powerpoint.png'
    },

    videoExtensions: ['.avi', '.mpeg', '.mpg', '.mov', '.mp4'],
    imageExtensions: ['.png', '.jpg', '.jpeg', '.gif', '.svg', '.tif'],
    videoThumbnail: {
        'avi': './images/video.png',
        'mpeg': './images/video.png',
        'mpg': './images/video.png',
        'mov': './images/video.png',
        'mp4': './images/video.png',
    },
    getPLMWebUrl: function () {
        if (window.location.hostname === this.environment.externalPLM.production.hostname)
            return this.environment.externalPLM.production.apiUrl;
        if (window.location.hostname === this.environment.externalPLM.uat.hostname)
            return this.environment.externalPLM.uat.apiUrl;
        if (window.location.hostname === this.environment.externalPLM.development.hostname)
            return this.environment.externalPLM.development.apiUrl;
        return this.environment.externalPLM.localhost.apiUrl;
    },
    isDevelopment: window.location.hostname === 'dev-arda.vanthiel.com' || window.location.hostname === 'localhost',
    getBOMExternalWebUrl: function () {
        if (sessionStorage.getItem('localhost') === 'true') {
            return 'https://127.0.0.1:8092/api/' // dev
        } else {
            return window.location.hostname === "arda.vanthiel.com" || window.location.hostname === "arda-vanthiel.azurewebsites.net"
                ? 'https://webapi001.vanthiel.com:8091/api/' // 'http://213.206.238.183:8090/api/'  prod
                : window.location.hostname === "dev-arda.vanthiel.com" || window.location.hostname === "arda-vanthiel-dev.azurewebsites.net"
                    ? 'https://webapi001.vanthiel.com:8092/api/' // change this later to updated url and port     dev
                    : 'http://localhost:6001/api/'  // dev
        }
    },
    getProdPlanningExternalWebUrl: function () {
        if (sessionStorage.getItem('localhost') === 'true') {
            return 'https://127.0.0.1:8094/api/'  // dev
        } else {
            return window.location.hostname === "arda.vanthiel.com" || window.location.hostname === "arda-vanthiel.azurewebsites.net"
                ? 'https://webapi001.vanthiel.com:8093/api/' // 'http://213.206.238.183:8090/api/'   prod
                : window.location.hostname === "dev-arda.vanthiel.com" || window.location.hostname === "arda-vanthiel-dev.azurewebsites.net"
                    ? 'https://webapi001.vanthiel.com:8094/api/' // change this later to updated url and port   dev
                    : 'http://localhost:6002/api/'   // dev
        }
    },
    getInventorIntegrationWebUrl: function () {
        if (sessionStorage.getItem('localhost') === 'true') {
            return 'https://localhost:5010/api/'  // dev
        } else {
            return window.location.hostname === "arda.vanthiel.com" || window.location.hostname === "arda-vanthiel.azurewebsites.net"
                ? 'https://arda-inventor-integration-webapi.azurewebsites.net/api/'
                : window.location.hostname === "dev-arda.vanthiel.com" || window.location.hostname === "arda-vanthiel-dev.azurewebsites.net"
                    ? 'https://arda-inventor-integration-webapi-dev.azurewebsites.net/api/'
                    : 'https://localhost:5010/api/'   // dev
        }
    },
    getProdPlanningTargetDBFromUrl: function () {
        if (sessionStorage.getItem('localhost') === 'true') {
            return '999'  // dev
        } else {
            return window.location.hostname === "arda.vanthiel.com" || window.location.hostname === "arda-vanthiel.azurewebsites.net"
                ? '999' // prod  - temporarily switch to 999 - later we will put bach to 110
                : window.location.hostname === "dev-arda.vanthiel.com" || window.location.hostname === "arda-vanthiel-dev.azurewebsites.net"
                    ? '999' // change this later to updated url and port   dev
                    : '999'   // dev
        }
    },
    emailIdFieldname: "emailId",
    supplierStatusFieldname: "statSup",
    customerStatusFieldname: "statCust",
    chargeBackRefNo: "chargeBackRefNo",
    isChargedBackCheckedFieldname: "isChargedBackChecked",
    isClaimRecap: 'isClaimRecap',
    isActive: 'isActive',
    level: 'level',
    desc: 'desc',
    code: 'code',
    contrEmpCode: 'contrEmpCode',
    contrEmpDesc: 'contrEmpDesc',
    contractTypeCode: 'contractTypeCode',
    accessGroupCode: 'accessGroupCode',
    companyCode: 'companyCode',
    company: 'company',
    supplierStatusToBeChargedBack: "To be Charged Back",
    supplierStatusAccepted: "Accepted",
    supplierStatusForcedAccepted: "Forced Accepted",
    customerStatusAccepted: "Accepted",
    customerStatusForcedAccepted: "Forced Accepted",

    getBomHeaders: 'BomHeader/list',
    getBomHeadersByBomFileId: 'BomHeader/listbyfileid',
    getBomHeadersByBomFileIds: 'BomHeader/listbyfileids',
    getBomLinesByBomHeaderId: 'BomLine/listbybomheaderid',
    getBomLinesByBomHeaderIds: 'BomLine/listbybomheaderids',
    deleteBom: 'BomHeader/delete',
    insertBom: 'BomHeader/insertbyid',
    uploadBom: 'Upload/bom',
    getLog: 'BomLine/getlog',
    getStepRelationsByBomHeaderId: 'BomStepRelation/listbyheaderid',
    getStepRelationsByBomHeaderIds: 'BomStepRelation/listbyheaderids',
    downloadTemplate: 'Download/template',
    getStepRelationLog: 'BomStepRelation/getlog',
    getBomFiles: 'BomFile/list',
    ecnCode: 'ECN',
    cpspcCode: "CPSPC",
    // erp-integration-service uploadDocTypes
    uploadDocTypes: {
        ecrCode: 'ECR',
        ecnCode: 'ECN',
        cpspcCode: 'CPSPC',
        testCode: 'TEST',
        labelReq: 'LABELREQ'
    },

    // production planning
    getProductionPlanningSchedule: 'ProdPlanning/schedule',
    getProductionPlanningFilteredSchedule: 'ProdPlanning/filteredSchedule',
    taskProcessResize: 'ProdPlanning/processresize',
    updateProductionPlanningSchedules: 'ProdPlanning/update',
    getSavedTaskItems: 'ProdPlanning/getTaskItems',
    // production planning filter options
    getProductionPlanningFilterOptions: 'FilterOptions/list',
    getProductionPlanningFilterOptionsByDates: 'FilterOptions/listbydates',

    // cache
    productionPlanningCacheClearAll: 'Cache/clearall',

    // inventor
    inventorGetBOMExport: 'Inventor/getbomexport',
    inventorSetBOMExport: 'Inventor/setbomexport',
    inventorSaveBOMExport: 'Inventor/savebomexport',
    inventorRemoveBOMExport: 'Inventor/removebomexport',
    isProductDuplicate: 'Product/isduplicate',

    // component
    componentExportedBOM: 'component/exportedboms',

    accessRightsComponents: {
        General: "General",
        Claims: "Claims",
        ClaimsList: "ClaimsList",
        ClaimRecaps: "ClaimRecaps",
        Chargeback: "Chargeback",
        Customer: "Customer",
        Reports: "Reports",
        Supplier: "Supplier",
        HR: "HR",
        EmployeeMaster: "EmployeeMaster",
        StandardRegistry: "StandardRegistry",
        RHWeb: "RHWeb",
        Queue: "Queue",
        Access: 'Access',
        CompensationBenefits: 'CompensationBenefits',
        EmploymentContracts: 'EmploymentContracts',
        ConsultingContracts: 'ConsultingContracts',
        Costing: "Costing",
        RequestQuotation: "RequestQuotation",
        Quotation: "Quotation",
        UploadBOM: "UploadBOM",
        BOMOverview: "BOMOverview",
        ProductionPlanning: "ProductionPlanning",
        ARDAUploads: "ARDAUploads",
        ARDAUploadsESN: "ARDAUploadsESN",
        ARDAUploadsCPSPC: "ARDAUploadsCPSPC",
        ARDAUploadsLABELREQ: "ARDAUploadsLABELREQ",
        eBOMPreview: "eBOMPreview",
        mBOMConversion: "mBOMConversion",
    },

    accessRightsActions: {
        view: 'view',
        attachment: 'attachment',
        accessGroup: 'vn',
        mainLocation: 'vietnam',
        supplierStatusFieldname: "statSup",
        customerStatusFieldname: "statCust",
        readonly: 'reaonly',
    },

    cacheKeys: {
        Employees: 'Employees',
    },
    maximumLength: {
        standardRegistry: {
            jobTitle: {
                code: 12,
                desc: 50
            },
            costCenter: {
                code: 8,
                desc: 50
            },
            costUnit: {
                code: 8,
                desc: 25
            },
            mainLocation: {
                code: 10,
                desc: 200,
            },
            officeLocation: {
                code: 10,
                desc: 200,
            },
            default: {
                code: 100,
                desc: 200,
            }
        }
    },
    locales: {
        'en-au': 'en-au',
        'en-ca': 'en-ca',
        'en-gb': 'en-gb',
        'en-ie': 'en-ie',
        'en-nz': 'en-nz',
        'en-us': 'en-us',
        'nl-be': 'nl-be',
        'nl': 'nl',
        'sk': 'sk',
        'cs': 'cs',
        'zh-cn': 'zh-cn',
        'zh-hk': 'zh-hk',
        'zh-tw': 'zh-tw',
        'ja': 'ja',
        'fr-ca': 'fa-ca',
        'fr-ch': 'fa-ch',
        'fr': 'fr',
        'vi-vn': 'vi-vn'
    },
    paging: {
        size: {
            twenty: 20,
            thirty: 30,
            fifty: 50,
            hundred: 100,
        },
        pageSize: [
            25,
            50,
            100
        ]
    },
    filterOptions: {
        name: {
            prodOrder: 'prodOrder',
            item: 'item',
            status: 'status',
            customer: 'customer',
            warehouse: 'warehouse'
        },
        type: {
            Item: 'Item',
            Status: 'Status',
            Customer: 'Customer',
            ProdOrder: 'ProdOrder',
            Warehouse: 'Warehouse',
        },
        statuses: [
            { id: '1', code: 'Open', description: 'Open' },
            { id: '2', code: 'Authorized', description: 'Authorized' },
            { id: '3', code: 'Rejected', description: 'Rejected' },
            { id: '4', code: 'Released', description: 'Released' },
            { id: '5', code: 'Printed', description: 'Printed' }
        ],
    },
    chartSettings: {
        treeSize: {
            chart: 550,
            grid: '99.015%',
            withoutVerticalScroll: '99.975%',
        },
        timeline: {
            width: 18,
            minWidth: 16,
        },
        splitterBar: {
            width: '0px',
            minWidth: '0px'
        }
    },
    databases: {
        prod: '110',
        dev: '999'
    },
    environment: {
        development: 'development',
        uat: 'uat',
        production: 'production',
        externalCosting: {
            production: {
                hostname: 'supplier.vanthiel.com',
                apiUrl: 'https://ext-vanthiel-supplier-costing-webapi.azurewebsites.net/'
            },
            uat: {
                hostname: 'uat-supplier.vanthiel.com',
                apiUrl: 'https://ext-vanthiel-supplier-costing-webapi-uat.azurewebsites.net/'
            },
            development: {
                hostname: 'dev-supplier.vanthiel.com',
                apiUrl: 'https://ext-vanthiel-supplier-costing-webapi-dev.azurewebsites.net/'
            },
            localhost: {
                hostname: '',
                apiUrl: 'http://localhost:5003/'
            }
        },
        externalPLM: {
            production: {
                hostname: 'arda.vanthiel.com',
                apiUrl: 'https://vanthiel-plm-webapi.azurewebsites.net/'
            },
            uat: {
                hostname: 'uat-arda.vanthiel.com',
                apiUrl: 'https://vanthiel-plm-webapi-uat.azurewebsites.net/'
            },
            development: {
                hostname: 'dev-arda.vanthiel.com',
                apiUrl: 'https://vanthiel-plm-webapi-dev.azurewebsites.net/'
            },
            localhost: {
                hostname: '',
                apiUrl: 'http://localhost:5007/'
            }
        }
    }
}