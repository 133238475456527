
import { styled } from '@mui/material';
import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { constants } from '../../config/settings';
import { AuthContext } from '../../context/AuthContext';
import { hasAccessRights } from '../../utils/utilities';
import { ProductionPlanningCache } from '../cache/ProdunctionPlanningCache';
import { PreviewBOMContainer } from '../inventor/PreviewBOMContainer';
import { ProductionPlanningContainer } from '../productionPlanning/ProductionPlanningContainer';
import { BOMOverview } from '../upload/reports/BOMOverview';
import { UploadContainer } from '../upload/UploadContainer';
import { SidebarNavigation } from './SidebarNavigation';
import { MBOMConversionContainer } from '../inventor/MBOMConversionContainer';
import { useExportBOMStore } from '../../store/ExportBOMStore';
import { UploadsDocContainer } from '../uploadsdoc/UploadsDocContainer';

const StyledSidebarContainer = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '& main': {
        maxWidth: '100%'
    }
});
export const SidebarContent = () => {
    const authValue = useContext(AuthContext);
    const { isPreviewMode } = useExportBOMStore();
    return (
        <BrowserRouter>
            {(isPreviewMode ?? false) === false && <SidebarNavigation />}
            <StyledSidebarContainer>
                <main>
                    <Routes>
                        {authValue?.auth?.userToken &&
                            hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.UploadBOM, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/" element={<UploadContainer />} />}
                        {authValue?.auth?.userToken &&
                            hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.BOMOverview, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/report" element={<BOMOverview />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.UploadBOM, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/uploads" element={<UploadContainer />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.ProductionPlanning, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/planning" element={<ProductionPlanningContainer />} />}
                        <Route path="/prodplanningcache/clearall" element={<ProductionPlanningCache />} />
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.eBOMPreview, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/preview/:itemCode" element={<PreviewBOMContainer />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.mBOMConversion, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/conversion" element={<MBOMConversionContainer />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.ARDAUploadsESN, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/uploadsecn" element={<UploadsDocContainer />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.ARDAUploadsCPSPC, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/uploadscpspc" element={<UploadsDocContainer />} />}
                        {hasAccessRights(authValue?.roleAccess, constants.accessRightsComponents.ARDAUploadsLABELREQ, constants.accessRightsActions.view, authValue?.auth.admin) &&
                            <Route path="/uploadslabelreq" element={<UploadsDocContainer />} />}
                    </Routes>
                </main>
            </StyledSidebarContainer>
        </BrowserRouter>
    )
}