import { styled } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import React,{ useEffect,FC } from "react";
import { useUploadGridHelper } from "./hooks/UploadGridHelper";
import { useUploadStore } from "../../../Types/Plm/UploadStore";
import { constants } from "../../../config/settings";
import { useUploadGridLoaders } from "./hooks/UploadGridLoaders";

export const GridWrapper = styled('div')({
    width: '99%',
    height: 'calc(100vh - 148px)',
    backgroundColor: '#fff',
    [`& .MuiDataGrid-root`]: {
        border: 'none',
        [`& .MuiDataGrid-columnHeaderTitle`]: {
            fontWeight: 600,
        },
    },
})

export interface SCCPCGridProps {
    docCode: string | undefined
}

export const SCCPCGrid:FC<SCCPCGridProps> = (props:SCCPCGridProps) => {
    const {docCode} = props;
    const apiRef = useGridApiRef();
    const { documentFiles, setCurrentComponent, loadingDocs, setTestGridProps,setECNGridProps } = useUploadStore();
    const { getUploadDocsByCode } = useUploadGridLoaders();
    const { uploadGridColumns, GridFilterModel } = useUploadGridHelper();
    useEffect(() => {
        setTestGridProps(props);
        setECNGridProps({docCode:''});
        setCurrentComponent(constants.uploadDocTypes.testCode);
        getUploadDocsByCode(docCode);
    }, []);
    return (
        <GridWrapper>
            <DataGridPro
                apiRef={apiRef}
                rowHeight={50}
                rows={documentFiles}
                columns={uploadGridColumns}
                showCellRightBorder={false}
                loading={loadingDocs}
                filterModel={GridFilterModel}
            />
        </GridWrapper>
    )
}